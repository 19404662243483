import {ICart as ICartFromCartApi} from '@wix/wixstores-client-storefront-sdk';
import {LineItemModel} from './LineItem.model';
import {AppliedCouponModel} from './AppliedCoupon.model';
import {ContactInfoModel} from './ContactInfo.model';
import {PriceModel} from './Price.model';
import {Cart, LineItem} from '@wix/ecom_current-cart';

export class CartModel {
  public id: string;
  public lineItems: LineItemModel[];
  public subtotal: PriceModel;
  public buyerNote: string;
  public currencyCode: string;
  public convertedCurrencyCode: string;
  public checkoutId: string;
  public appliedCoupon: AppliedCouponModel;
  public purchaseFlowId: string;
  public selectedShippingOptionId: string;
  public contactInfo: ContactInfoModel;
  public subtotalAfterDiscounts: PriceModel;

  constructor(params: CartModel) {
    this.id = params.id;
    this.lineItems = params.lineItems;
    this.subtotal = params.subtotal;
    this.buyerNote = params.buyerNote;
    this.currencyCode = params.currencyCode;
    this.convertedCurrencyCode = params.convertedCurrencyCode;
    this.checkoutId = params.checkoutId;
    this.appliedCoupon = params.appliedCoupon;
    this.purchaseFlowId = params.purchaseFlowId;
    this.selectedShippingOptionId = params.selectedShippingOptionId;
    this.contactInfo = params.contactInfo;
    this.subtotalAfterDiscounts = params.subtotalAfterDiscounts;
  }

  public static fromGQL(cart: ICartFromCartApi) {
    return new CartModel({
      id: cart?.cartId ?? undefined,
      lineItems: (cart?.items ?? []).map((item) => LineItemModel.fromGQL(item)),
      subtotal: PriceModel.fromGQL({
        convertedAmount: cart?.convertedTotals?.subTotal,
        formattedConvertedAmount: cart?.convertedTotals?.formattedSubTotal,
      }),
      buyerNote: cart?.buyerNote ?? undefined,
      currencyCode: cart?.currencyFormat?.code ?? undefined,
      convertedCurrencyCode: cart?.convertedCurrencyFormat?.code ?? undefined,
      checkoutId: cart?.checkoutId ?? undefined,
      appliedCoupon: cart?.appliedCoupon ? AppliedCouponModel.fromGQL(cart?.appliedCoupon) : undefined,
      purchaseFlowId: cart?.purchaseFlowId ?? undefined,
      selectedShippingOptionId: cart?.selectedShippingOption?.id ?? undefined,
      contactInfo: cart?.destination ? ContactInfoModel.fromGQL(cart?.destination) : undefined,
      subtotalAfterDiscounts: PriceModel.fromGQL({
        convertedAmount: cart?.convertedTotals?.itemsTotal,
        formattedConvertedAmount: cart?.convertedTotals?.formattedItemsTotal,
      }),
    });
  }

  public static fromSDK(cart: Cart) {
    return new CartModel({
      id: cart._id,
      lineItems: cart.lineItems.map((lineItem: LineItem) => LineItemModel.fromSDK(lineItem)),
      subtotal: undefined,
      buyerNote: cart.buyerNote,
      currencyCode: cart.currency,
      convertedCurrencyCode: cart.conversionCurrency,
      checkoutId: cart.checkoutId,
      appliedCoupon: AppliedCouponModel.fromSDK(cart.appliedDiscounts[0]?.coupon),
      purchaseFlowId: cart.purchaseFlowId,
      selectedShippingOptionId: cart.selectedShippingOption.code,
      contactInfo: cart.contactInfo ? ContactInfoModel.fromSDK(cart.contactInfo) : undefined,
      subtotalAfterDiscounts: undefined,
    });
  }
}
