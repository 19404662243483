export const SPECS = {
  CartTooltipWithoutNumber: 'specs.stores.CartTooltipWithoutNumber',
  CartOOIThunderboltCSSOptimization: 'specs.stores.CartOOIThunderboltCSSOptimization',
  HideDeliveryMethodPickerWithTimeSlots: 'specs.stores.HideDeliveryMethodPickerWithTimeSlots',
  fixEmailNotifierInCart: 'specs.stores.fixEmailNotifierInCart',
  ShouldUseSideCart: 'specs.stores.ShouldUseSideCart',
  ShouldUseOpenSideCartApiInAddToCart: 'specs.stores.ShouldUseOpenSideCartApiInAddToCart',
  ShouldUseEcomPlatformPublicApiAddToCartInPP: 'specs.stores.ShouldUseEcomPlatformPublicApiAddToCartInPP',
  MakeCartItemNameLink: 'specs.stores.MakeCartItemNameLink',
  ErrorMessage: 'specs.stores.AddErrorMessageInCouponWhenTheInputIsEmpty',
  AddSidePaddingToCartSettingsForDesktop: 'specs.stores.AddSidePaddingSettingsOnCartPageForDesktop',
  UsePageUrlOnItemThumbnail: 'specs.stores.UsePageUrlOnItemThumbnail',
  AddSlotToCartPage: 'specs.stores.AddSlotToCartPage',
  NavigateToCartWhenDontShowSideCartOnMobile: 'specs.stores.NavigateToCartWhenDontShowSideCartOnMobile',
  ExpressCheckoutInSideCart: 'specs.stores.ExpressCheckoutInSideCart',
  shouldChangeToBiCatalogV2: 'specs.stores.shouldChangeToBiCatalogV2',
  FixSelectedThumbnailBug: 'specs.stores.FixSelectedThumbnailBug',
  ShowCssPerBreakpointIndicationsSideCart: 'specs.stores.ShowCssPerBreakpointIndicationsSideCart',
  SupportDeliveryViolationOnCart: 'specs.stores.SupportDeliveryViolationOnCart',
  UseAllStylesInCart: 'specs.stores.UseAllStylesInCart',
  UseGetSettingsWithDefaults: 'specs.stores.UseGetSettingsWithDefaults',
  SideCartElementsVisibilityInCss: 'specs.stores.SideCartElementsVisibilityInCss',
  CheckoutFromSDKInExpressCheckoutButtons: 'specs.stores.CheckoutFromSDKInExpressCheckoutButtons',
  ActionsDirectlyFromCurrentCartService: 'specs.stores.ActionsDirectlyFromCurrentCartService',
  CartFromSDKWhenShowShippingOrShowTax: 'specs.stores.CartFromSDKWhenShowShippingOrShowTax',
  StopUseSelectedMembershipName: 'specs.stores.StopUseSelectedMembershipName',
} as const;
