export class UrlModel {
  public relativePath: string;
  public url: string;

  constructor(params: UrlModel) {
    this.relativePath = params.relativePath;
    this.url = params.url;
  }

  public static fromGQL(urlPart: string, pageUrl: string) {
    return new UrlModel({
      relativePath: urlPart,
      url: pageUrl,
    });
  }

  public static fromSDK(relativePath: string, url: string) {
    return new UrlModel({
      relativePath,
      url,
    });
  }
}
